<template>
  <div>
    <v-container fluid fill-height>
      <c-ecosystem-nav :ecosystemId="ecosystemId"></c-ecosystem-nav>

      <v-row>
        <v-col>
          <v-container>
            <!-- Title -->
            <v-row>
              <v-col offset-sm="1" cols="11" class="pb-0 heading-row">
                <c-page-heading
                  class="text-left"
                  :heading="$t('users.importLog.importUsers')"
                  :subHeading="$t('users.importLog.log')"
                ></c-page-heading>
              </v-col>
            </v-row>

            <!-- subtitle -->
            <v-row>
              <v-col offset-sm="1" cols="11">
                <div class="text-h6 font-weight-light mb-2" data-cy="totalRecordsImported">
                  {{ $t('users.importLog.totalRecordsImported') }} {{ totalImports }}.
                  {{ $t('users.importLog.succesfullyImported') }} {{ successfulImports }},
                  {{ $t('users.importLog.failed') }} {{ failedImports }}.
                </div>
              </v-col>
            </v-row>

            <v-row v-if="logs?.length">
              <v-col offset-sm="1" cols="12" :sm="10" class="pr-sm-0">
                <v-card rounded="xl" class="light-shadow">
                  <v-card-title class="primary white--text px-4 px-sm-8 py-3">
                    <v-row class="table-heading">
                      <v-col class="font-weight-bold" cols="10"> Description </v-col>
                      <v-col class="font-weight-bold text-center px-0" cols="2"> Status </v-col>
                    </v-row>
                  </v-card-title>

                  <v-card-text
                    v-if="loading"
                    class="pa-4 text-center pt-6"
                    style="min-height: 100px"
                  >
                    <v-progress-circular indeterminate size="40"></v-progress-circular>
                  </v-card-text>

                  <v-card-text
                    v-else
                    v-for="(log, index) in logs"
                    class="px-4 px-sm-8 py-3 log-row"
                    :data-cy="`log-row-${index}`"
                    :key="log.index"
                  >
                    <v-row>
                      <v-col
                        cols="10"
                        class="font-weight-light d-flex align-center"
                        :style="{ fontSize: logDescriptionTextSize }"
                      >
                        {{ log.description }}
                      </v-col>

                      <v-col cols="2" class="d-flex justify-center align-center">
                        <v-btn
                          color="#1A1E38"
                          :width="btnSize"
                          :height="btnSize"
                          depressed
                          fab
                          small
                          data-cy="user-role-icon"
                          class="no-pointer"
                        >
                          <v-icon v-if="log.status === 0" color="white" :size="iconSize">
                            fas fa-check fa-stack-1x fa-inverse
                          </v-icon>
                          <v-icon v-else color="white" :size="iconSize">
                            fas fa-exclamation-triangle fa-stack-1x fa-inverse
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  props: ['ecosystemId'],
  data: () => ({
    logs: [],
    loading: true,
    fromPath: undefined,
    toPath: undefined,
  }),
  computed: {
    iconSize() {
      return this.windowWidth <= 420 ? '15px' : '20px'
    },
    btnSize() {
      return this.windowWidth <= 420 ? '30px' : '40px'
    },
    logDescriptionTextSize() {
      return this.windowWidth <= 420 ? '1rem' : '1.3rem'
    },
    totalImports() {
      return this.logs?.length
    },
    successfulImports() {
      return this.logs?.filter(log => log.status == 0).length
    },
    failedImports() {
      return this.logs?.filter(log => log.status != 0).length
    },
  },
  created() {
    document.addEventListener('focusin', this.focusChanged)
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromPath = from.path
      vm.toPath = to.path
    })
  },
  mounted() {
    if (this.fromPath !== this.toPath.replace('/log', '')) {
      this.$router.push(`/ws/${this.ecosystemId}/users/import`)
    }
    this.logs = this.$store.getters['ecosystems/userImportLog'](this.ecosystemId)
    this.loading = false
  },
  beforeDestroy() {
    document.removeEventListener('focusin', this.focusChanged)
  },
}
</script>
<style scoped lang="scss">
.log-row {
  font-size: 1.3rem;
  border-top: 1px dotted #cbc9f7;
  line-height: 1.5em;
}
</style>
