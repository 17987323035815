<template>
  <div>
    <v-container fluid fill-height>
      <c-ecosystem-nav :ecosystemId="ecosystemId"></c-ecosystem-nav>

      <v-row>
        <v-col>
          <v-container :fluid="$vuetify.breakpoint.lgAndDown">
            <!-- Title -->
            <v-row>
              <v-col offset-sm="1" cols="11" class="pb-0 heading-row">
                <c-page-heading
                  class="text-left"
                  :heading="$t('users.import.import')"
                  :subHeading="$t('users.import.users')"
                ></c-page-heading>
              </v-col>
            </v-row>

            <!-- subtitle -->
            <v-row>
              <v-col offset-sm="1" cols="11" class="pb-0">
                <div class="text-h6 font-weight-light mb-2" data-cy="importUsersInToYourEcosystem">
                  {{ $t('users.import.importUsersInToYourEcosystem') }}
                </div>
                <a href="/ImportFile.csv" style="text-decoration: none">
                  <div
                    class="text-h6 mt-5 mb-5 download-text"
                    data-cy="importUsersInToYourEcosystem"
                  >
                    <v-icon size="16" color="primary" class="pr-3">{{ 'fa-download' }}</v-icon
                    >{{ $t('users.import.downloadATemplateCsvFile') }}
                  </div>
                </a>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- first Col -->
                <v-row>
                  <v-col offset-sm="1" cols="11" :md="5">
                    <!-- Form -->
                    <v-form ref="form" @submit="onSubmit" class="pa-2">
                      <v-row>
                        <v-col cols="12" class="pl-0 pt-0 pb-4 my-0 ml-1">
                          <div class="font-weight-bold text-h6 mb-1">
                            File <span class="red--text">*</span>
                          </div>
                          <div
                            class="d-flex file-input-container pointer"
                            data-cy="file-input-container"
                            :class="{
                              focussed: fileInputFocussed,
                              missingFileOutline: missingFileOutline,
                            }"
                          >
                            <v-file-input
                              :placeholder="placeholderText"
                              prepend-icon=""
                              background-color="#f3efed"
                              height="35px"
                              hide-details
                              :clearable="false"
                              accept=".csv"
                              class="pa-0 file-input"
                              id="file-input"
                              v-model="file"
                              @click="missingFileOutline = false"
                              @change="validateFile"
                              data-cy="file-input"
                            >
                            </v-file-input>
                            <div
                              class="browse-btn d-flex justify-center align-center"
                              data-cy="browse-btn"
                              @click="chooseFile"
                            >
                              {{ $t('users.import.browse') }}
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="12" class="my-0 pl-1">
                          <div class="font-weight-bold text-h6 mb-1">Default Space</div>
                          <c-search-select
                            :options="defaultSpaces"
                            :selected="values.defaultSpaceId"
                            :placeholder="'Select'"
                            v-on:optionSelected="updateDefaultSpaceId"
                            data-cy="default-space-select"
                            class="default-space-select"
                            id="defaultSpaceSelect"
                          />
                        </v-col>
                        <v-col cols="12" class="my-0 pl-1">
                          <div class="font-weight-bold text-h6 mb-1">
                            {{ $t('common.timezone') }}
                          </div>
                          <m-timezone-picker
                            :showSearch="true"
                            :timezones="timeZones"
                            orderBy="utcOffset"
                            :menuProps="timezonePickerMenuProps"
                            v-model="values.userTimeZone"
                            data-cy="userTimeZone-select"
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col class="my-0 pl-1">
                          <v-btn
                            data-cy="button"
                            type="submit"
                            x-large
                            @click.prevent="onSubmit"
                            :disabled="$store.getters['ui/loading'].isLoading"
                            color="primary"
                            rounded
                            class="submit-btn-text submit-btn mt-md-6"
                          >
                            <div class="button-text">{{ $t('users.import.importFile') }}</div>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { MTimezonePicker } from 'gatherings-storybook'
export default {
  components: {
    MTimezonePicker,
  },
  props: ['ecosystemId'],
  data: () => ({
    values: {
      defaultSpaceId: '',
      userTimeZone: '',
    },
    file: null,
    defaultSpaces: [],
    fileInputFocussed: false,
    missingFileOutline: false,
    timeZones: [],
    timezonePickerMenuProps: {
      width: '100%',
    },
    ecosystem: null,
  }),
  computed: {
    placeholderText() {
      return this.$vuetify.breakpoint.xs
        ? this.$t('users.import.selectAFile')
        : this.$t('users.import.selectAFileToContinue')
    },
  },
  created() {
    document.addEventListener('focusin', this.focusChanged)
  },
  beforeDestroy() {
    document.removeEventListener('focusin', this.focusChanged)
  },
  async mounted() {
    await this.$store.dispatch('spaces/fetchDefaults', {
      ecosystemId: this.ecosystemId,
      user: this.$store.getters['auth/user'],
    })

    this.defaultSpaces = await this.$store.getters['spaces/defaults'].map(g => {
      return {
        value: g.id,
        text: g.name,
      }
    })

    this.ecosystem = this.$store.getters['ecosystems/getById'](this.ecosystemId)

    this.timeZones = await this.$store.getters['timeZones/all']
    this.values.userTimeZone = await this.$store.getters['timeZones/default']({
      ecosystemId: this.ecosystemId,
    })
  },
  methods: {
    chooseFile() {
      document.getElementById('file-input').click()
    },
    validateFile() {
      if (this.file && this.file?.type !== 'text/csv') {
        this.$store
          .dispatch('ui/launchSnackbar', {
            color: 'error',
            message: this.$t('files.add.youCanOnlyUseCsvFilesToImportUsers'),
            buttonColor: 'white',
          })
          .then(() => {
            this.file = undefined
          })
      }
    },
    focusChanged(event) {
      const el = event.target
      if (el.type === 'file') {
        this.fileInputFocussed = true
      } else {
        this.fileInputFocussed = false
      }
    },
    update({ name, value }) {
      this.values[name] = value
    },
    updateDefaultSpaceId(option) {
      this.values.defaultSpaceId = option
    },
    async onSubmit() {
      this.submitAttempted = true
      if (!this.file) {
        this.missingFileOutline = true
        return
      }

      this.$store.dispatch('ui/toggleLoading', { isLoading: true })
      this.$store
        .dispatch('ecosystems/importUsers', {
          ecosystemId: this.ecosystemId,
          values: this.values,
          file: this.file,
        })
        .then(
          () => {
            const params = {
              color: 'green',
              message: 'User import processed',
              buttonColor: 'white',
            }
            this.$store.dispatch('ui/launchSnackbar', params)
            this.$store.dispatch('ui/toggleLoading', { isLoading: false })
            this.$router.push({ path: `/ws/${this.ecosystemId}/users/import/log` })
          },
          error => {
            this.$store.dispatch('ui/toggleLoading', { isLoading: false })
            this.message =
              (error.response && error.response.data) || error.message || error.toString()
          }
        )
    },
  },
}
</script>
<style scoped lang="scss">
.heading-row {
  height: 120px;
}
::v-deep .v-input__slot {
  width: 100%;
  border-radius: 15px;
  border: none;
  background-color: #f3efed;
  padding: 10px 20px 10px 20px;
}
::v-deep .v-select__slot {
  width: 100%;
  border: none;
  padding: 0px 20px 0px 20px;
}
::v-deep .v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  width: 0%;
}
::v-deep .v-text-field > .v-input__control > .v-input__slot:after {
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0;
}

.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}

::v-deep .v-text-field > .v-input__control > .v-input__slot {
  cursor: pointer;
}

.browse-btn {
  border-radius: 15px;
  background-color: #eaecef;
  line-height: 1.5;
  color: #495057;
  width: 80px;
  font-size: 1.15rem;
  border: solid;
  border-width: 1px;
  border-color: lightgray;
}
.file-input-container {
  background-color: #f3efed;
  border-radius: 15px;
  border: solid;
  border-width: 1px;
  border-color: lightgray;
}
.focussed {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.missingFileOutline {
  border-color: #dc3545;
}
::v-deep .v-select__slot {
  padding-left: 0px;
}
.download-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}
// Restyling of vue multiselect to match timezone input
.default-space-select {
  height: 45px;
  border-radius: 15px !important;
  display: flex !important;
  align-items: center !important;
  border: 1px solid #9e9e9e !important;
}
.default-space-select i {
  padding-top: 15px !important;
}
</style>
<style lang="scss">
#defaultSpaceSelect > .text {
  font-size: 16px !important;
  color: #716c6c !important;
}
.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
  top: 0.65rem;
}

.ui.dropdown > .dropdown.icon {
  font-size: 16px;
}

.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown:hover .menu {
  border-color: #807ef0;
}
</style>
